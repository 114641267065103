<template>
  <LSection class="digiQmenuBar bg-white shadow">
    <template #body>
      <nav
        :key="showMenu"
        class="menuBar pt-2 h-20 text-gray-700 text-base font-medium dark:bg-gray-900"
      >
        <div
          class="flex flex-wrap justify-between mt-3 h6:mt-0 items-center z-10"
        >
          <a href="/" class="flex">
            <img
              src="../assets/images/digiqLogo.png"
              class="h-8 h6:mt-0 h6:h-16"
            />
          </a>
          <div class="hidden k1:visible k1:flex k1:gap-4 text-lg">
            <router-link
              to="/"
              :class="{ 'text-black font-bold': $route.name === 'home' }"
            >
              Home
            </router-link>
            <router-link
              to="/partners"
              :class="{ 'text-black font-bold': $route.name === 'partners' }"
            >
              Partners
            </router-link>
            <router-link
              to="/students"
              :class="{ 'text-black font-bold': $route.name === 'students' }"
              >Students
            </router-link>
            <router-link
              to="/networks"
              :class="{ 'text-black font-bold': $route.name === 'networks' }"
            >
              Networks
            </router-link>
            <router-link
              to="/mobility"
              :class="{ 'text-black font-bold': $route.name === 'mobility' }"
            >
              Mobility
            </router-link>

            <router-link to="/track"> DigiQ-Track</router-link>
            <router-link
              to="/contact"
              :class="{ 'text-black font-bold': $route.name === 'contact' }"
            >
              Contact
            </router-link>
          </div>
          <div class="k1:ml-8 gap-2 items-center flex k1:gap-4">
            <button
              type="button"
              class="bg-transparent inline-flex items-center p-2 ml-0 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 k1:hidden"
              @click="showMenu = !showMenu"
            >
              <span class="sr-only">Open navigation menu</span>
              <svg
                class="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <!--  Burger Menu Routes  -->
          <div
            v-if="showMenu"
            id="navbar-default"
            class="w-full k1:hidden k1:w-auto text-xl modal-slide-in"
            :class="{ hidden: !showMenu }"
            @click="() => (showMenu = false)"
          >
            <div
              class="flex flex-col p-4 mt-4 bg-gray-50 rounded-lg border border-gray-200 md:flex-row k1:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700 shadow"
            >
              <router-link
                to="/"
                class="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                :class="{
                  'text-white bg-blue-700 hover:bg-gray-600':
                    $route.name === 'home',
                  'hover:bg-gray-200 hover:text-gray-700':
                    $route.name !== 'home',
                }"
              >
                Home
              </router-link>

              <router-link
                to="/partners"
                class="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                :class="{
                  'text-white bg-blue-700 hover:bg-gray-600':
                    $route.name === 'partners',
                  'hover:bg-gray-200 hover:text-gray-700':
                    $route.name !== 'partners',
                }"
              >
                Partners
              </router-link>
              <router-link
                to="/students"
                class="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                :class="{
                  'text-white bg-blue-700 hover:bg-gray-600':
                    $route.name === 'students',
                  'hover:bg-gray-200 hover:text-gray-700':
                    $route.name !== 'students',
                }"
              >
                Students
              </router-link>
              <router-link
                to="/networks"
                class="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                :class="{
                  'text-white bg-blue-700 hover:bg-gray-600':
                    $route.name === 'networks',
                  'hover:bg-gray-200 hover:text-gray-700':
                    $route.name !== 'networks',
                }"
              >
                Networks
              </router-link>
              <router-link
                to="/mobility"
                class="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                :class="{
                  'text-white bg-blue-700 hover:bg-gray-600':
                    $route.name === 'mobility',
                  'hover:bg-gray-200 hover:text-gray-700':
                    $route.name !== 'mobility',
                }"
              >
                Mobility
              </router-link>
              <router-link
                to="/track"
                class="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                :class="{
                  'text-white bg-blue-700 hover:bg-gray-600':
                    $route.name === 'track',
                  'hover:bg-gray-200 hover:text-gray-700':
                    $route.name !== 'track',
                }"
              >
                DigiQ-Track
              </router-link>
              <router-link
                to="/contact"
                class="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                :class="{
                  'text-white bg-blue-700 hover:bg-gray-600':
                    $route.name === 'contact',
                  'hover:bg-gray-200 hover:text-gray-700':
                    $route.name !== 'contact',
                }"
              >
                Contact
              </router-link>
            </div>
          </div>
        </div>
      </nav>
    </template>
  </LSection>
</template>
<script>
import LSection from "@/components/layout/LSection.vue";
import { ref, onMounted, onBeforeUnmount, computed } from "vue";
import { useUserPermissions } from "@/composables/useUserPermissions";
import { useStore } from "vuex";
import { LOGOUT } from "@/store/operations";
import { capitalize } from "@/lib/typeHelpers";

export default {
  components: {
    LSection,
  },
  name: "DigiQMenuBar",
  setup() {
    const { isLoggedIn, account, user } = useUserPermissions();
    const showMenu = ref(false);
    const store = useStore();
    const onLogout = () => {
      store.dispatch(LOGOUT);
    };
    // if the screen width is less than 1000px, show the menu
    const checkScreenWidth = () => {
      return window.innerWidth < 1000;
    };
    // Add closeMenu method
    const closeMenu = () => {
      showMenu.value = false;
    };
    // Split first word of account.name, but only when account.name exists
    const splitName = computed(() =>
      capitalize(account.value?.name.split(" ")[0] ?? "")
    );

    // Add onClickOutside method
    const onClickOutside = (event) => {
      const menuElement = event.target.closest(".menuBar");
      if (!menuElement) {
        closeMenu();
      }
    };

    // Add and remove the onClickOutside listener
    onMounted(() => {
      document.addEventListener("click", onClickOutside);
    });

    onBeforeUnmount(() => {
      document.removeEventListener("click", onClickOutside);
    });

    return {
      isLoggedIn,
      onLogout,
      showMenu,
      account,
      splitName,
      user,
      checkScreenWidth,
    };
  },
};
</script>

<style scoped></style>
